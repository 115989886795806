// 外观
export const CS_EXTERIOR = {
  崭新出厂: {
    abbr: '崭新',
    color: '#1f7640'
  },
  略有磨损: {
    abbr: '略磨',
    color: '#59a726'
  },
  久经沙场: {
    abbr: '久经',
    color: '#eda344'
  },
  破损不堪: {
    abbr: '破损',
    color: '#ae5754'
  },
  战痕累累: {
    abbr: '战痕',
    color: '#8e3331'
  },
  无涂装: {
    abbr: '无涂装',
    color: '#979CAE'
  },
  'Factory New': {
    abbr: '崭新',
    color: '#1f7640'
  },
  'Minimal Wear"': {
    abbr: '略磨',
    color: '#59a726'
  },
  'Field-Tested': {
    abbr: '久经',
    color: '#eda344'
  },
  'Well-Worn': {
    abbr: '破损',
    color: '#ae5754'
  },
  'Battle-Scarred': {
    abbr: '战痕',
    color: '#8e3331'
  },
  'Not Painted': {
    abbr: '无涂装',
    color: '#979CAE'
  }
}

export const CS_RARITY = {
  保密: 'rgb(211,44,230)',
  违禁: 'rgb(228,174,57)',
  隐秘: 'rgb(235,75,75)',
  受限: 'rgb(136,71,255)',
  军规级: 'rgb(75,105,255)',
  工业级: 'rgb(94,152,217)',
  消费级: 'rgb(144,172,204)',
  非凡: 'rgb(235,75,75)',
  卓越: 'rgb(136,71,255)',
  奇异: 'rgb(211,44,230)',
  高级: 'rgb(75,105,255)',
  普通级: 'rgb(144,172,204)',
  大师: 'rgb(235,75,75)'
}

// 闪亮：#E62CAD   全息：#6D1DFF   金色：#E21C1C   纯正：#00614A
export const STICKER_COLOR = {
  金色: '#E21C1C',
  闪亮: '#E62CAD',
  全息: '#6D1DFF'
}

export const CS_QUALITY = {
  // 枪械 - 类别
  'StatTrak™': {
    abbr: 'ST',
    color: '#c85f2c'
  },
  纪念品: {
    abbr: '纪',
    color: '#ffa800'
  },
  // 匕首 - 类别
  '★ StatTrak™': {
    abbr: '★ ST',
    color: '#7b46a2'
  },
  '★': {
    abbr: '★',
    color: '#7b46a2'
  }
}

export const DOTA_RARITY = {
  普通: '#90ACCC',
  罕见: '#5E98D9',
  稀有: '#4B69FF',
  神话: '#8847FF',
  传说: '#D32CE6',
  不朽: '#E4AE39',
  至宝: '#9FD353',
  远古: '#EB4B4B'
}

export const DOTA_QUALITY = {
  标准: {
    color: '#cccccc',
    abbr: '标准'
  },
  铭刻: {
    color: '#ff7837',
    abbr: '铭刻'
  },
  吉祥: {
    color: '#2cc62c',
    abbr: '吉祥'
  },
  英雄传世: {
    color: 'rgb(198,123,255)',
    abbr: '英雄'
  },
  纯正: {
    color: 'rgb(125,237,146)',
    abbr: '纯正'
  },
  亲笔签名: {
    color: 'rgb(163,225,82)',
    abbr: '亲笔'
  },
  冻人: {
    color: 'rgb(90,176,255)',
    abbr: '冻人'
  },
  凶煞: {
    color: 'rgb(183,158,255)',
    abbr: '凶煞'
  },
  基础: {
    color: 'rgb(169,169,169)',
    abbr: '基础'
  },
  冥灵: {
    color: 'rgb(255,115,115)',
    abbr: '冥灵'
  },
  融合: {
    color: 'rgb(183,158,255)',
    abbr: '融合'
  },
  独特: {
    color: 'rgb(198,123,255)',
    abbr: '独特'
  },
  尊享: {
    color: 'rgb(197,197,197)',
    abbr: '尊享'
  },
  上古: {
    color: 'rgb(136,179,255)',
    abbr: '上古'
  },
  绝版: {
    color: 'rgb(255,255,255)',
    abbr: '绝版'
  },
  青睐: {
    color: 'rgb(255,255,0)',
    abbr: '青睐'
  },
  传奇: {
    color: 'rgb(255,86,86)',
    abbr: '传奇'
  },
  全息: {
    color: '#6D1DFF',
    abbr: '全息'
  },
  金色: {
    color: '#E21C1C',
    abbr: '金色'
  }
}

export const CS_FILTERS = {
  Exterior: {
    width: 90
  },
  Quality: {
    width: 105
  },
  Type: {
    width: 112,
    dropdownHeight: 330,
    secondKey: 'weapon'
  }
}

export const DOTA2_FILTERS = {
  Hero: {
    width: 105,
    primaryItemDisabled: true
  },
  Rarity: {
    width: 90
  },
  Quality: {
    width: 90
  },
  Type: {
    width: 105
    // secondKey:
  }
}

export const LEVEL_COLORS = {
  '1st': '#5c617a',
  '2nd': '#5c617a',
  '3rd': '#5c617a',
  '4th': '#5c617a',
  '5th': '#5c617a',
  '6th': '#5c617a',
  '7th': '#5c617a',
  '8th': '#5c617a',
  '9th': '#5c617a',
  '10th': '#5c617a',
  P1: '#5c617a',
  P2: '#5c617a',
  P3: '#5c617a',
  P4: '#5c617a',
  T1: '#AFB0B2',
  T2: '#AFB0B2',
  T3: '#AFB0B2',
  T4: '#AFB0B2',
  单面全蓝: '#AFB0B2',
  官图太阳: '#5c617a',
  红宝石: '#d85653',
  绿宝石: '#5eb75f',
  蓝宝石: '#6F9FFB',
  黑珍珠: '#D7DAE1',
  Fake: '#5c617a',
  'Max fake': '#5c617a'
}

export const ITEM_INFO_COLOR_DICT = {}
for (const data of [CS_EXTERIOR, CS_QUALITY, DOTA_QUALITY]) {
  for (const [key, value] of Object.entries(data)) {
    ITEM_INFO_COLOR_DICT[key] = value.color
  }
}
for (const data of [CS_RARITY, STICKER_COLOR, DOTA_RARITY, LEVEL_COLORS]) {
  for (const [key, value] of Object.entries(data)) {
    ITEM_INFO_COLOR_DICT[key] = value
  }
}
